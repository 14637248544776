// const KeystrokeHandler = require('keystroke-handler');
const Mousetrap = require('mousetrap');
const { attachDelegateEvent, isMobileBrowser } = require('./utils');
const { generateHelpModal, handleKeyStroke, keyBindings } = require('./keystrokes');

const toggleMenu = (elem, state) => {
    let otherMenus = Array.from(document.querySelectorAll('[role="menu"]:not([hidden])')).filter(
        (menu) => menu != elem
    );
    otherMenus.forEach((menu) => toggleMenu(menu, 'hide'));
    switch (state) {
        case 'show':
            elem.setAttribute('aria-visible', 'true');
            elem.removeAttribute('hidden');
            document.addEventListener(
                'click',
                () => {
                    toggleMenu(elem, 'hide');
                },
                {
                    once: true,
                }
            );
            if (!elem.hasAttribute('data-persist')) {
                document.addEventListener(
                    'click',
                    () => {
                        toggleMenu(elem, 'hide');
                    },
                    {
                        once: true,
                    }
                );
            }
            try {
                elem.querySelector('[type="text"]').focus();
                elem.querySelector('[type="text"]').select();
            } catch (err) {}
            break;
        case 'hide':
            elem.setAttribute('aria-visible', 'false');
            elem.setAttribute('hidden', '');
            break;
    }
}; // toggleMenu

const handleToggler = function (e) {
    // e.stopImmediatePropagation(); // JSK: Do we need this?
    const button = this;
    const target = document.getElementById(button.getAttribute('aria-controls'));
    switch (target.getAttribute('aria-visible')) {
        case 'false':
            toggleMenu(target, 'show');
            button.setAttribute('aria-expanded', 'true');
            break;
        case 'true':
            toggleMenu(target, 'hide');
            button.setAttribute('aria-expanded', 'false');
            break;
    }
}; // handleToggler

const refocusHandler = function (e) {
    const elem = document.querySelector(e.detail.value);
    if (elem) {
        elem.focus();
    }
}; // refocusHandler

const onModalLoaded = (e) => {
    switch (e.detail.id) {
        case 'modal-help':
            if (!isMobileBrowser()) {
                // only add keystrokes if *not* mobile
                Mousetrap.bind(Object.keys(keyBindings), handleKeyStroke);
                generateHelpModal();
            }
            break;
    }
}; // onModalLoaded

const activateRowFilter = () => {
    const filterInput = document.getElementById('filter-rows');
    if (filterInput) {
        filterInput.addEventListener('keyup', function () {
            let filterValue = filterInput.value.toUpperCase();

            let rows = document.querySelectorAll('.table:has([data-filterable]) tbody tr');

            if (filterValue.length < 3) {
                // If less than 3 characters, show all rows
                for (let i = 0; i < rows.length; i++) {
                    rows[i].style.display = '';
                }
            } else {
                // Filter table rows based on filterInput
                for (let i = 0; i < rows.length; i++) {
                    let filterableCells = rows[i].querySelectorAll('[data-filterable]');
                    let found = false;

                    // Check each filterable cell in the row
                    filterableCells.forEach(function (cell) {
                        let txtValue = cell.textContent || cell.innerText;
                        if (txtValue.toUpperCase().indexOf(filterValue) > -1) {
                            found = true;
                        }
                    });

                    // Show or hide the row based on the search result
                    rows[i].style.display = found ? '' : 'none';
                }
            }
        });
    }
}; // activateRowFilter

const clearField = function (e) {
    const input = document.getElementById(this.getAttribute('data-clear'));
    input.value = '';
};

const handlePasswordToggle = function (e) {
    const button = this;
    const input = button.previousElementSibling;
    const icon = button.querySelector('use');
    const icon_path = icon.getAttribute('xlink:href').split('#')[0];

    if (input.type === 'password') {
        input.type = 'text';
        icon.setAttribute('xlink:href', `${icon_path}#visibility`);
    } else {
        input.type = 'password';
        icon.setAttribute('xlink:href', `${icon_path}#visibility_off`);
    }
}; // handlePasswordToggle

const handleCopy = function (e) {
    // Don't trigger copy if clicking on the toggle button
    if (e.target.closest('[data-toggle="password"]')) {
        return;
    }

    const input = this.querySelector('.toggle-password-input');
    const textToCopy = input.value;

    // Copy to clipboard
    try {
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                console.log('Copied:', textToCopy); // Debug log

                // Visual feedback
                const originalValue = input.value;
                const originalType = input.type;
                input.type = 'text';
                input.value = 'Copied!';

                setTimeout(() => {
                    input.value = originalValue;
                    input.type = originalType;
                }, 1000);
            })
            .catch((err) => {
                console.error('Failed to copy:', err); // Debug log
            });
    } catch (err) {
        console.error('Clipboard API not available:', err); // Debug log

        // Fallback method
        input.select();
        document.execCommand('copy');

        // Visual feedback
        const originalValue = input.value;
        const originalType = input.type;
        input.type = 'text';
        input.value = 'Copied!';

        setTimeout(() => {
            input.value = originalValue;
            input.type = originalType;
        }, 1000);
    }
}; // handleCopy

const addInteractions = () => {
    attachDelegateEvent('click', '[aria-controls][data-toggle]', handleToggler);
    attachDelegateEvent('click', '[data-clear]', clearField);
    attachDelegateEvent('click', '[data-toggle="password"]', handlePasswordToggle);
    attachDelegateEvent('click', '.toggle-password-wrapper', handleCopy); // Changed selector
    document.body.addEventListener('refocus', refocusHandler);
    document.body.addEventListener('modal:loaded', onModalLoaded);
    activateRowFilter();

    console.log('Interactions added'); // Debug log
};

module.exports = {
    addInteractions,
};
